import { mapGetters } from 'vuex'
import { datadogRum } from '@datadog/browser-rum'
import { seoFactory } from './page/seo'
import { itemsByNameOfCollection, collectionToArray } from '~/utils/collection'
import { PAYMENT_METHODS_SLUG } from '~/utils/constants/pages'
import detectMobileOs from '~/services/detect-mobile-os'

// General create page mixin function to be used for all pages we have.
// Returns a mixin that you can configure by passing options.

export default ({
  pageType,
  cloudfrontCache,
  indexedByDefault = true,
} = {}) => {
  return {
    data() {
      return {
        contentfulPageData: {},
        contentfulPageCountryOverrideData: {},
        pageViewAttrs: {},
        pageViewTracked: false,
        pageType,
      }
    },
    computed: {
      ...mapGetters('contentful', [
        'sharedHeader',
        'sharedFooter',
        'sharedMenu',
        'sharedCountry',
        'sharedMerchant',
        'sharedPaymentMethods',
        'sharedIndexedLanguages',
      ]),
      ...mapGetters('context', [
        'language',
        'languages',
        'marketplacePrefix',
        'direction',
        'currency',
        'domain',
        'isGlobalMarketplace',
        'countryCode',
        'recommerceLocale',
        'customerCountry',
      ]),
      uniquePageKey() {
        return `${this.marketplacePrefix}-${this.currency.code}-${this.slug}`
      },
      mobileOs() {
        return detectMobileOs()
      },
      seo() {
        return seoFactory({
          metaTitle:
            this.metaTitle ||
            this.contentfulPageCountryOverrideData.metaTitle ||
            this.contentfulPageData.metaTitle,
          ogTitle: this.ogTitle,
          metaRobotsConfiguration:
            this.contentfulPageCountryOverrideData.metaRobotsConfiguration ||
            this.contentfulPageData.metaRobotsConfiguration,
          metaDescription:
            this.contentfulPageCountryOverrideData.metaDescription ||
            this.contentfulPageData.metaDescription,
          ogDescription: this.ogDescription,
          ogImage: this.ogImage,
          metaCanonicalLink:
            this.contentfulPageCountryOverrideData.metaCanonicalLink ||
            this.contentfulPageData.metaCanonicalLink,
          structuredData:
            this.contentfulPageCountryOverrideData.structuredData ||
            this.contentfulPageData.structuredData,
          slug: this.slug,
          lang: this.language.language,
          countryCode: this.country.abv,
          indexedLanguages: this.sharedIndexedLanguages,
          allLanguages: this.languages.map(({ language }) => language),
          indexedByDefault,
          noIndex: Boolean(
            this.pageType === 'product' &&
              (!this.product?.variants || this.product?.variants?.length === 0)
          ),
          query: this.$route.query,
        })
      },
      footer() {
        return {
          logo: this.sharedMerchant.logo,
          footerLinksGroup: collectionToArray(
            this.sharedFooter.footerLinksGroup
          ).map(group => ({
            title: group.title,
            groupLinks: collectionToArray(group.pageLinks),
          })),
          paymentMethods: this.sharedPaymentMethods,
          paymentMethodsPage: {
            url: `/${this.marketplacePrefix}/${PAYMENT_METHODS_SLUG}`,
          },
          legalLinks: collectionToArray(this.sharedFooter.legalPageLinks).map(
            page => ({
              title: page.title,
              url: `${this.marketplacePrefix}/${page.slug}`,
            })
          ),
        }
      },
      header() {
        if (!this.sharedHeader) return null
        return {
          menu: this.sharedMenu,
          logo: this.sharedMerchant.logo,
          usps: this.sharedHeader.usps,
        }
      },
      country() {
        return this.sharedCountry
      },
      slug() {
        return this.$route.path.replace(
          new RegExp(`/${this.marketplacePrefix}/?`),
          ''
        )
      },
      script() {
        const includeStructuredData = this.seo.structuredData

        return [
          includeStructuredData && {
            type: 'application/ld+json',
            json: this.structuredData,
          },
        ].filter(Boolean)
      },
      isMobile() {
        return this.$mq === 'sm' || this.$mq === 'md'
      },
      isDesktop() {
        const { $mq } = this
        return $mq === 'lg' || $mq === 'xl'
      },
    },
    httpHeaders: ({ query }) => {
      if (query.preview !== undefined || !cloudfrontCache) return {}
      return {
        'Cache-Control': 's-maxage=600, public',
        'Referrer-Policy': 'same-origin',
      }
    },
    head() {
      const isPreview = this.$route.query.preview === 'true'
      const meta = this.seo?.meta || []
      const link = this.seo?.link || []

      if (isPreview) {
        meta.push({ name: 'robots', content: 'noindex, nofollow, noarchive' })
        meta.push({
          name: 'googlebot',
          content: 'noindex, nofollow, noarchive',
        })
      }

      return {
        htmlAttrs: {
          lang: this.language.language.replace(/-[a-z]*/, c => c.toUpperCase()),
        },
        titleTemplate: `%s | ${this.domain}`,
        title: this.seo.title,
        meta,
        link,
        script: this.script,
        __dangerouslyDisableSanitizers: ['innerHTML'],
      }
    },
    methods: {
      shouldLoadThirdPartyScript(scriptName) {
        const { loadThirdPartyScripts } = this.$route.query

        return (
          !loadThirdPartyScripts ||
          loadThirdPartyScripts.split(',').includes(scriptName)
        )
      },
      getSection(sectionName) {
        const sections = this.getSections(sectionName)
        if (!sections) return null
        return sections[0]
      },
      getSections(sectionName) {
        const countryOverrideCollection = this.contentfulPageCountryOverrideData
          ?.sectionsCollection || { items: [] }

        const overrideSections = itemsByNameOfCollection(
          countryOverrideCollection,
          sectionName
        )

        if (overrideSections && overrideSections.length > 0)
          return overrideSections
        return itemsByNameOfCollection(
          this.contentfulPageData.sectionsCollection,
          sectionName
        )
      },
      trackPageView() {
        if (this.pageViewTracked) return
        this.pageViewTracked = true
        this.$gtmEnhanced.trackPageView(
          this.$route,
          this.$store,
          this.pageType,
          this.pageViewAttrs
        )
        this.$store.dispatch('products/setPageViewEventLogged', true)
      },
      startDatadogRum(pageName) {
        if (pageName)
          datadogRum.startView({
            service: pageName,
          })
      },
    },
    watch: {
      '$fetchState.pending': function(pending) {
        if (pending || this.isLocalMarketplace || process.server) return
        this.trackPageView()
        this.startDatadogRum(this.$route?.name)
      },
    },
    mounted() {
      if (this.isLocalMarketplace) return
      if (this.shouldLoadThirdPartyScript('gtm')) this.$gtm.init()
      if (!this.$fetchState?.pending) {
        this.trackPageView()
        this.startDatadogRum(this.$route?.name)
      }

      this.$store.dispatch('context/setAidInStorage', {
        query: this.$route.query,
      })
    },
  }
}
