/**
 * Determine the mobile operating system using browser user Agent
 *
 * @returns (String)
 */

const detectMobileOs = (): string => {
  const userAgent = navigator.userAgent || navigator.vendor

  if (/android/i.test(userAgent)) {
    return 'android'
  }

  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return 'ios'
  }

  return ''
}

export default detectMobileOs
