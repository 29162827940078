import GLOBAL_COUNTRY from '~/utils/constants/global-country.json'

const createMetaTag = (name, content) => ({
  name,
  hid: name,
  content,
})

const createDefaultCanonicalLink = ({ lang, countryCode, slug }) => {
  // Default canonical slug is self or the parent if it's a variant page
  const [derivedSlug] = slug.split('/')
  return [
    `https://${process.env.DOMAIN}`,
    lang.toLowerCase(),
    countryCode,
    derivedSlug,
  ]
    .filter(Boolean)
    .join('/')
}

const createCanonicalTag = href => {
  return {
    rel: 'canonical',
    href,
  }
}

const defaultRobots = shouldRobotsIndexPage =>
  createMetaTag(
    'ROBOTS',
    shouldRobotsIndexPage ? 'index, follow' : 'noindex, nofollow'
  )

const generateHreflangs = (indexedLanguages, country, slug, canonicalLang) => {
  const sortedLanguages = indexedLanguages.sort(lang =>
    lang === canonicalLang ? -1 : 1
  )
  return [...sortedLanguages, 'x'].map(lang => {
    const href = [
      `https://${process.env.DOMAIN}`,
      lang === 'x' ? 'en' : lang,
      country,
      slug,
    ]
      .filter(Boolean)
      .join('/')
    return {
      rel: 'alternate',
      href,
      hreflang:
        lang === 'x'
          ? 'x-default'
          : lang.replace(/-[a-z]*/, c => c.toUpperCase()),
    }
  })
}

export const seoFactory = ({
  metaTitle,
  ogTitle,
  metaRobotsConfiguration,
  metaDescription,
  ogDescription,
  ogImage,
  metaCanonicalLink,
  structuredData,
  slug,
  lang,
  countryCode,
  // indexedLanguages,
  allLanguages,
  indexedByDefault,
  noIndex,
  query,
}) => {
  // Temporary until LMP is gone. Will be solved in ticket: https://creativegroupdev.atlassian.net/browse/OP-924
  // const currentLangIsIndexed = indexedLanguages.includes(lang.toLowerCase())
  const currentLangIsIndexed = countryCode !== GLOBAL_COUNTRY.code.toLowerCase()
  const canonicalTag = createCanonicalTag(
    metaCanonicalLink ||
      createDefaultCanonicalLink({
        lang,
        countryCode,
        slug,
      })
  )

  // Temporary set ROBOTS as noindex for these languages
  // until duplicated Apple Gift Card and Google Play Gift Card products are cleaned
  const appleNoIndexCountriesList = ['at', 'es', 'it', 'gb']
  const googleNoIndexCountriesList = [
    'at',
    'es',
    'gb',
    'it',
    'nl',
    'de',
    'fr',
    'be',
    'pl',
    'ch',
  ]

  // Keeping 'apple-web' product for testing purposes
  const appleGiftCardNames = ['apple-gift-card', 'apple-web']
  const googleGiftCardNames = ['google-play-gift-card']

  let metaRobots
  // For page with auth modal (i.e. ?auth=...), do not include meta robots directive
  if (query.auth) {
    metaRobots = ''
  } else if (
    (appleGiftCardNames.includes(slug) &&
      appleNoIndexCountriesList.includes(countryCode)) ||
    (googleGiftCardNames.includes(slug) &&
      googleNoIndexCountriesList.includes(countryCode))
  ) {
    metaRobots = createMetaTag('ROBOTS', 'noindex')
  } else {
    // This is the initial way of setting metaRobots value
    // Should be returned when it is possible
    metaRobots = metaRobotsConfiguration
      ? createMetaTag('ROBOTS', metaRobotsConfiguration)
      : defaultRobots(currentLangIsIndexed && indexedByDefault && !noIndex)
  }

  return {
    title: metaTitle,
    structuredData,
    meta: [
      metaRobots,
      ...(metaDescription
        ? [createMetaTag('DESCRIPTION', metaDescription)]
        : []),
      ...(ogTitle ? [createMetaTag('og:title', ogTitle)] : []),
      ...(ogDescription
        ? [createMetaTag('og:description', ogDescription)]
        : []),
      ...(ogImage ? [createMetaTag('og:image', ogImage)] : []),
    ],
    link: [
      ...(currentLangIsIndexed ? [canonicalTag] : []),
      ...(indexedByDefault && currentLangIsIndexed && !noIndex
        ? generateHreflangs(allLanguages, countryCode, slug, lang)
        : []),
    ],
  }
}
