<template>
  <layout
    :key="uniquePageKey"
    :header="header"
    :footer="footer"
    :country="country"
  >
    <loading v-if="$fetchState.pending" />
    <div v-else class="flex-1 pt-8">
      <hero-banner
        v-if="sectionHeroBanner"
        :title="sectionHeroBanner.title"
        :src="sectionHeroBanner.src"
        :is-mobile="isMobile"
      />

      <category-list
        :category-cards="categoryCards"
        :show-categories-on-desktop="false"
      />

      <popular-brand-list
        v-if="popularProducts"
        :brands="popularProducts.products"
        :title="popularProducts.title"
      />

      <brand-list
        v-for="(section, index) in taxonList"
        :key="index"
        :brands="section.brands"
        :title="section.title"
        :has-prefix-icon="false"
        :category-page="section.categoryPage"
        :data-test-brand-category="section.categoryPage.slug"
      />

      <activation-info
        v-if="sectionInfoBlocks && sectionInfoBlocks.infoBlocks"
        :title="sectionInfoBlocks.title"
        :has-prefix-icon="false"
        :info-blocks="sectionInfoBlocks.infoBlocks"
      />

      <container>
        <cdp-container
          id="cdp-homepage-footer-all-banner"
          :cdp-country="$route.params.country"
          :cdp-language="$route.params.language"
          cdp-label="GMP"
          class="w-full mx-auto mt-10 md:mt-15 mb-10 md:mb-15"
        />
      </container>

      <portal-target :name="portals.PORTAL_NAME_SWITCH_COUNTRY" />

      <suggest-country
        v-if="showSuggestCountry"
        @hide="showSuggestCountry = false"
      />
      <portal-target :name="portals.PORTAL_SUGGESTION_COUNTRY" />

      <usps-block enable-cta is-last />
    </div>
  </layout>
</template>

<script>
import { mapGetters } from 'vuex'
import GLOBAL_COUNTRY from '~/utils/constants/global-country.json'
import { portals } from '~/enums'
import createPageMixin from '~/mixins/page'
import Layout from '~/components/layout/default'
import { firstOfCollection, collectionToArray } from '~/utils/collection'
import { contentfulToImage, recommerceToImageByType } from '~/utils/image'
import {
  UspsBlock,
  HeroBanner,
  CategoryList,
  BrandList,
  PopularBrandList,
  Loading,
  ActivationInfo,
} from '~/components/organisms'
import { SuggestCountry } from '~/components/molecules'
import { Container } from '~/components/grid'
import { CdpContainer } from '~/components/renderers'
import { hydrateCache } from '~/apis/clients/rest/recommerce/cache'

export default {
  components: {
    UspsBlock,
    Layout,
    Loading,
    HeroBanner,
    CategoryList,
    ActivationInfo,
    BrandList,
    PopularBrandList,
    SuggestCountry,
    CdpContainer,
    Container,
  },
  mixins: [
    createPageMixin({
      pageType: 'home',
      cloudfrontCache: true,
      seo: {
        origin: 'contentful',
      },
    }),
  ],
  data() {
    return {
      popularProductsData: {},
      recommerceTaxonData: [],
      taxons: [],
      showSuggestCountry: false,
      taxonParameters: {
        limit: 12,
        isHomepage: true,
      },
    }
  },
  async fetch() {
    const homepageRequest = this.homepageRequest()
    const {
      data: { homepageCollection, homepageCountryOverrideCollection },
    } = await homepageRequest

    this.contentfulPageData = firstOfCollection(homepageCollection)
    if (homepageCountryOverrideCollection)
      this.contentfulPageCountryOverrideData =
        firstOfCollection(homepageCountryOverrideCollection) || {}

    const taxons = await this.taxonsRequest(
      this.getSections('SectionBrandList')
    )

    const [[popularProducts], taxonData] = taxons.reduce(
      (acc, taxon) => {
        const filteredProducts = taxon.products.filter(
          ({ isAvailableOnWeb }) => isAvailableOnWeb
        )
        acc[taxon.slug === 'popular-products' ? 0 : 1].push({
          ...taxon,
          products: filteredProducts,
        })
        return acc
      },
      [[], []]
    )

    this.popularProductsData = popularProducts || {}
    this.recommerceTaxonData = (taxonData || []).filter(Boolean)
  },
  computed: {
    ...mapGetters('context', [
      'customerCountry',
      'redeemCountryEqualsCustomerCountry',
    ]),
    ...mapGetters('contentful', ['country']),
    portals() {
      return portals
    },
    taxonList() {
      if (!this.recommerceTaxonData || this.recommerceTaxonData.length === 0)
        return []
      return this.recommerceTaxonData.map(taxon => ({
        title: taxon.name,
        brands: taxon.products.map(product => ({
          logo: recommerceToImageByType(product, 'product_card'),
          slug: product.slug,
          title: product.nameInCategoryPage || product.name,
          countries: product.countries,
        })),
        categoryPage: { slug: taxon.slug },
      }))
    },
    sectionHeroBanner() {
      const heroBanner = this.getSection('SectionHeroBanner')
      if (!heroBanner?.banner) return null
      return {
        ...heroBanner,
        src: {
          desktop: contentfulToImage(heroBanner.banner),
          mobile: contentfulToImage(
            heroBanner.bannerMobile || heroBanner.banner
          ),
        },
      }
    },
    categoryCards() {
      const categoryList = this.getSection('SectionCategoryList')
      const list = categoryList
        ? collectionToArray(categoryList.categoryCardsCollection)
        : []

      return list
    },
    popularProducts() {
      if (!this.popularProductsData?.products) return null
      const products = this.popularProductsData.products.map(product => ({
        title: product.nameInCategoryPage || product.name,
        slug: product.slug,
        brandFeedImage: recommerceToImageByType(product, 'product_card'),
        countries: product.countries,
      }))
      return {
        products,
        title: this.popularProductsData.name,
      }
    },
    sectionAppBanner() {
      const sectionAppBanner = this.getSection('SectionAppBanner')
      if (!sectionAppBanner) return null
      return {
        ...sectionAppBanner,
        imageDesktop: contentfulToImage(sectionAppBanner.imageDesktop),
        imageMobile: contentfulToImage(sectionAppBanner.imageMobile),
      }
    },
    sectionInfoBlocks() {
      const sectionInfoBlocks = this.getSection('SectionInfoBlocks')
      if (!sectionInfoBlocks) return null
      return {
        ...sectionInfoBlocks,
        infoBlocks: collectionToArray(sectionInfoBlocks.infoBlocksCollection),
      }
    },
  },
  mounted() {
    const hasCountryCookie = this.$cookies.get('picked_country')

    if (
      !this.redeemCountryEqualsCustomerCountry &&
      this.customerCountry.code !== GLOBAL_COUNTRY.code &&
      !hasCountryCookie
    )
      this.showSuggestCountry = true

    if (!this.$fetchState?.pending) {
      hydrateCache(
        {
          recommerceLocale: this.recommerceLocale,
          endpoint: 'taxons',
          params: this.taxonParameters,
          redemptionCountry: this.countryCode,
          currency: this.currency.code,
        },
        this.taxons
      )
    }
  },
  methods: {
    homepageRequest() {
      return this.$contentful('homepage')({
        locale: this.$store.getters['context/cmsLocale'],
        countryCode: this.$store.getters['context/countryCode'],
      })
    },
    async taxonsRequest(brandList) {
      this.taxons = await this.$recommerce('taxons')(this.taxonParameters)
      const existingCategorySlugs = brandList.map(
        ({ categoryPage }) => categoryPage.slug
      )
      return this.taxons
        .filter(
          ({ slug }) =>
            existingCategorySlugs.includes(slug) || slug === 'popular-products'
        )
        .sort(
          // sorting according to Contentful order
          (a, b) =>
            existingCategorySlugs.indexOf(a.slug) -
            existingCategorySlugs.indexOf(b.slug)
        )
    },
  },
}
</script>
